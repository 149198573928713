import ReactCookieConsent, { Cookies } from 'react-cookie-consent';

import Button from 'src/kit/button';
import Text from 'src/kit/text';

export default function CookieConsent() {
  const { pathname } = window.location;

  // Preview page is used by the backend to generate the PDF and Thumbnail of the CV
  // It's internal use only and should not render anything other than the CV
  if (pathname === '/preview') {
    return null;
  }

  return (
    <ReactCookieConsent
      location="bottom"
      buttonText="Accept all"
      declineButtonText="Decline all"
      cookieName="dotcv-cookie-consent"
      style={{ background: '#27282b', alignItems: 'center', zIndex: 999999 }}
      ButtonComponent={Button}
      buttonStyle={{ margin: 0, background: '#00a1ff', color: '#fff', borderRadius: '4px' }}
      declineButtonStyle={{ margin: 0, background: 'transparent', color: '#fff', borderRadius: '4px' }}
      expires={150}
      enableDeclineButton
      flipButtons
      overlayClasses="!bg-black/70 bg-opacity-50 !z-[99999]"
      overlay
      buttonWrapperClasses="w-full md:w-auto flex items-center justify-center gap-2 pr-4 pb-4 md:pb-0"
      onAccept={() => {
        Cookies.set('dotcv-cookie-consent', JSON.stringify({ 0: true, 1: true, 2: true }), { expires: 150 });
      }}
      onDecline={() => {
        Cookies.set('dotcv-cookie-consent', JSON.stringify({ 0: false, 1: false, 2: false }), { expires: 150 });
      }}
    >
      <Text color="white" type="subtle" data-ui="content">
        <strong>dotcv</strong> utilizes cookies and similar techologies to make a user’s experience more efficient. By
        clicking “Accept all” you consent to the use of all cookies; by clicking “Decline all” only strictly necessary
        cookies will be placed. You can view our{' '}
        <Text as="a" color="blue300" href="https://dotcv.gr/cookies" target="_blank">
          cookies policy
        </Text>
        .
      </Text>
    </ReactCookieConsent>
  );
}
