const createQueryKeys = () => {
  const templates = () => ['template'];
  const template = (id: string) => [...templates(), id];

  const resumes = () => ['resumes'];
  const resume = (id: string) => [...resumes(), id];

  const permissions = () => ['permissions'];
  const permission = (id: string) => [...permissions(), id];

  return {
    templates,
    template,
    resumes,
    resume,
    permissions,
    permission
  };
};

const queryKeys = createQueryKeys();

export default queryKeys;
