import styles from './styles.module.scss';

export type ProfileProps = {
  avatar?: string | null;
  name?: string | null;
};

const Profile = (props: ProfileProps) => {
  const { avatar, name } = props;

  return (
    <div className={styles.profile}>
      <img className={styles.avatar} src={avatar || ''} alt={`Profile picture of ${name}`} />
    </div>
  );
};

export default Profile;
