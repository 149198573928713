import { useCallback, useState } from 'react';

import useAuth from '../useAuth';

export default function useWaitAuth() {
  const [ready, setReady] = useState(false);

  useAuth({
    onAuthLoad: useCallback(() => setReady(true), [])
  });

  return ready;
}
