import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import Text from 'src/kit/text';

import styles from './styles.module.scss';

const validFlavors = ['primary', 'dark', 'white', 'transparent'] as const;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  flavor?: (typeof validFlavors)[number];
  responsive?: boolean;
  rounded?: boolean;
  size?: 'small' | 'medium';
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    className,
    responsive,
    rounded,
    flavor = 'primary',
    type = 'button',
    size = 'medium',
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      {...rest}
      type={type}
      className={clsx(className, styles.button, styles[size], {
        [styles.responsive]: responsive,
        [styles[flavor]]: validFlavors.includes(flavor),
        [styles.rounded]: rounded
      })}
    >
      <Text type="body-medium" className={styles.text}>
        {children}
      </Text>
    </button>
  );
});

export default Button;
