import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import MainLayout from 'src/layouts/main/layout';

import DashboardLoading from 'src/pages/dashboard/loading';

import CookieConsent from 'src/components/cookieConsent';
import Loader from 'src/components/loader';
import Overlay from 'src/components/overlay';

import withAuthProtection from 'src/hocs/withAuthProtection';
import withUnauthProtection from 'src/hocs/withUnauthProtection';

import useWaitAuth from 'src/hooks/useWaitAuth';

const Login = withUnauthProtection(lazy(() => import('./pages/login')));
const SignUp = withUnauthProtection(lazy(() => import('./pages/signup')));
const PasswordReset = withUnauthProtection(lazy(() => import('./pages/passwordReset')));
const VerifyEmail = withAuthProtection(lazy(() => import('./pages/verifyEmail')));
const Dashboard = withAuthProtection(lazy(() => import('./pages/dashboard')));
const Editor = withAuthProtection(lazy(() => import('./pages/editor')));
const MyResumes = withAuthProtection(lazy(() => import('./pages/resumes')));
const Builder = withAuthProtection(lazy(() => import('./pages/builder')));
const Preview = lazy(() => import('./pages/preview'));

const OverlayLoader = () => (
  <Overlay>
    <Loader />
  </Overlay>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <MainLayout>
        <Suspense fallback={<DashboardLoading />}>
          <Dashboard />
        </Suspense>
      </MainLayout>
    )
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<OverlayLoader />}>
        <Login />
      </Suspense>
    )
  },
  {
    path: '/signup',
    element: (
      <Suspense fallback={<OverlayLoader />}>
        <SignUp />
      </Suspense>
    )
  },
  {
    path: '/forgot',
    element: (
      <Suspense fallback={<OverlayLoader />}>
        <PasswordReset />
      </Suspense>
    )
  },
  {
    path: '/verify-email',
    element: (
      <Suspense fallback={<OverlayLoader />}>
        <VerifyEmail />
      </Suspense>
    )
  },
  {
    path: '/editor/:id',
    element: (
      <MainLayout>
        <Suspense fallback={<OverlayLoader />}>
          <Editor />
        </Suspense>
      </MainLayout>
    )
  },
  {
    path: '/my-resumes',
    element: (
      <MainLayout>
        <Suspense fallback={<OverlayLoader />}>
          <MyResumes />
        </Suspense>
      </MainLayout>
    )
  },
  {
    path: '/preview',
    element: (
      <Suspense fallback={<OverlayLoader />}>
        <Preview />
      </Suspense>
    )
  },
  {
    path: '/admin/builder',
    element: (
      <MainLayout>
        <Suspense fallback={<OverlayLoader />}>
          <Builder />
        </Suspense>
      </MainLayout>
    )
  }
]);

function App() {
  const authReady = useWaitAuth();

  if (!authReady) {
    return <OverlayLoader />;
  }

  return (
    <>
      <RouterProvider router={router} />

      <CookieConsent />
    </>
  );
}

export default App;
