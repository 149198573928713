import clsx from 'clsx';

import { IconType } from 'react-icons';

import Button, { ButtonProps } from 'src/kit/button';

import styles from './styles.module.scss';

export type ToolButtonProps = ButtonProps & {
  Icon: IconType;
};

const ToolButton = (props: ToolButtonProps) => {
  const { Icon, children, className, ...rest } = props;

  return (
    <Button flavor="white" {...rest} className={clsx(className, styles['tool-button'])}>
      <Icon size={24} />
      {children}
    </Button>
  );
};

export default ToolButton;
