import { IoTimeOutline } from 'react-icons/io5';
import { LuPlus } from 'react-icons/lu';

import Skeleton from 'src/kit/skeleton';
import Text from 'src/kit/text';

import useAuth from 'src/hooks/useAuth';

import ToolButton from './components/toolButton';

import styles from './styles.module.scss';

function Editor() {
  const { user } = useAuth();

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <Text as="p" type="h3">
          Welcome {user?.displayName}!
        </Text>
      </div>

      <div>
        <ToolButton Icon={LuPlus} disabled>
          <span>New resume</span>
        </ToolButton>
      </div>

      <div>
        <Text as="h4" type="h3" className={styles['recents-header']}>
          <IoTimeOutline size={28} />
          <span>Recents</span>
        </Text>

        <div className={styles.recents}>
          <Skeleton height={300} />
          <Skeleton height={300} />
        </div>
      </div>
    </div>
  );
}

export default Editor;
