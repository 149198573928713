import clsx from 'clsx';

import { LuArrowLeftRight } from 'react-icons/lu';

import Button, { ButtonProps } from 'src/kit/button';

import styles from './styles.module.scss';

const Toggler = ({ className, ...props }: ButtonProps) => {
  return (
    <Button {...props} flavor="white" className={clsx(className, styles.toggler)}>
      <LuArrowLeftRight size={16} />
    </Button>
  );
};

export default Toggler;
