import { useCallback, useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import { GoogleAuthProvider, sendEmailVerification, signInWithPopup, updateProfile, User } from 'firebase/auth';
import { create } from 'zustand';
import { useQueryClient } from '@tanstack/react-query';

import { auth } from 'src/api/firebase';

type AuthStore = {
  user: User | null;
  setUser: (user: User | null) => void;
};

const useUserStore = create<AuthStore>((set) => ({
  user: auth.currentUser,
  setUser: (user: User | null) => set({ user })
}));

type UseAuthProps = {
  onAuthLoad?: (user: User | null) => void;
};

const useAuth = ({ onAuthLoad }: UseAuthProps = {}) => {
  const queryClient = useQueryClient();
  const { setUser, user } = useUserStore();

  useLayoutEffect(() => {
    if (user) {
      return;
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }

      onAuthLoad?.(user || null);
    });

    return () => {
      unsubscribe();
    };
  }, [onAuthLoad, setUser, user]);

  const updateName = useCallback(
    async (displayName: string) => {
      if (!user) {
        return;
      }

      await updateProfile(user, { displayName: displayName });
      setUser({ ...user, displayName });
    },
    [user, setUser]
  );

  const signInWithGoogle = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const signOut = useCallback(async () => {
    queryClient.clear();
    await auth.signOut();
    setUser(null);
  }, [setUser, queryClient]);

  const sendVerificationEmail = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      await sendEmailVerification(user);
      toast.success('Verification email sent!');
    } catch (error) {
      if (error instanceof Error) {
        toast.error('Error sending verification email');
      }
    }
  }, [user]);

  return { setUser, signInWithGoogle, signOut, updateName, sendVerificationEmail, user };
};

export default useAuth;
