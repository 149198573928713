import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { IconType } from 'react-icons';

import Text from 'src/kit/text';

import { PolymorphicComponentPropsWithRef, PolymorphicRef } from 'src/types/polymorphic';

import { useSidebar } from '../shared/provider';

import styles from './styles.module.scss';

const getTitleFromChildren = (children: React.ReactNode) => {
  if (typeof children === 'string') {
    return children;
  }

  return undefined;
};

type Props = {
  children: React.ReactNode;
  Icon: IconType;
};

export type ItemProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type ItemComponent = (<C extends React.ElementType = 'span'>(props: ItemProps<C>) => React.ReactNode) & {
  displayName?: string;
};

const Item: ItemComponent = forwardRef(
  <C extends React.ElementType = 'span'>(
    { Icon, children, className, ...rest }: ItemProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const { collapsed } = useSidebar();

    return (
      <li>
        {/* @ts-expect-error TODO: Fix Issue due to polymorphic,*/}
        <Text
          {...rest}
          ref={ref}
          type="p-ui-medium"
          color="white"
          title={getTitleFromChildren(children)}
          className={clsx(className, styles.item, {
            [styles.collapsed]: collapsed
          })}
        >
          <div className={styles['icon-container']}>{Icon && <Icon size={16} />}</div>

          {!collapsed && <div>{children}</div>}
        </Text>
      </li>
    );
  }
);

Item.displayName = 'Sidebar.Item';

export default Item;
