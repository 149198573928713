import { useLayoutEffect, useState } from 'react';

interface Breakpoint {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  lt: Record<string, boolean>;
  gt: Record<string, boolean>;
}

const breakpoints = {
  xs: 599,
  sm: 959,
  md: 1279,
  lg: 1919,
  xl: 5000
} as const;

type BreakpointKeys = keyof typeof breakpoints;

const getLt = (windowWidth: number) => {
  return Object.keys(breakpoints).reduce((acc, key) => {
    if (windowWidth < breakpoints[key as BreakpointKeys]) {
      acc[key as BreakpointKeys] = true;
    } else {
      acc[key as BreakpointKeys] = false;
    }
    return acc;
  }, {} as Record<BreakpointKeys, boolean>);
};

const getGt = (windowWidth: number) => {
  return Object.keys(breakpoints).reduce((acc, key) => {
    if (windowWidth > breakpoints[key as BreakpointKeys]) {
      acc[key as BreakpointKeys] = true;
    } else {
      acc[key as BreakpointKeys] = false;
    }
    return acc;
  }, {} as Record<BreakpointKeys, boolean>);
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    lt: {},
    gt: {}
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      setBreakpoint({
        xs: windowWidth <= breakpoints.xs,
        sm: windowWidth >= breakpoints.xs && windowWidth <= breakpoints.sm,
        md: windowWidth >= breakpoints.sm && windowWidth <= breakpoints.md,
        lg: windowWidth >= breakpoints.md && windowWidth <= breakpoints.lg,
        xl: windowWidth >= breakpoints.lg && windowWidth <= breakpoints.xl,
        lt: getLt(windowWidth),
        gt: getGt(windowWidth)
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return breakpoint;
};

export default useBreakpoint;
