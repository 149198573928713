import { doc, getDoc } from 'firebase/firestore';

import { db } from 'src/api/firebase';

import { UserPermission } from 'src/types/permissions';

const getUserPermissions = async (uid: string) => {
  const document = await getDoc(doc(db, `userPermissions/${uid}`));

  if (!document.exists()) {
    throw new Error('Template not found');
  }

  return {
    ...(document.data() as UserPermission),
    id: document.id
  };
};

export default { getUserPermissions };
