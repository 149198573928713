import { HTMLAttributes } from 'react';
import clsx from 'clsx';

import Text from 'src/kit/text';

import defaultWallpaper from 'src/assets/images/wallpaper-default.webp';

import { useSidebar } from '../shared/provider';

import styles from './styles.module.scss';

const getWallpaperStyle = (wallpaper: string) => {
  return {
    '--wallpaper-url': `url(${wallpaper})`
  } as React.CSSProperties;
};

type ProfileProps = HTMLAttributes<HTMLDivElement> & {
  wallpaper?: string | null;
  avatar?: string | null;
  name?: string | null;
  occupation?: string | null;
};

const Profile = ({ wallpaper, avatar, name, occupation, className, style, ...props }: ProfileProps) => {
  const { collapsed } = useSidebar();

  return (
    <div
      {...props}
      className={clsx(className, styles.profile, {
        [styles.collapsed]: collapsed
      })}
    >
      <div className={styles['profile-section']}>
        <div
          className={styles.wallpaper}
          style={{
            ...style,
            ...getWallpaperStyle(wallpaper || defaultWallpaper)
          }}
        />
        <img
          className={styles.avatar}
          src={avatar || ''}
          alt={`Profile picture of ${name}`}
          referrerPolicy="no-referrer"
        />
      </div>

      <div className={styles.headline}>
        {name && (
          <Text as="span" type="subtle-semibold" color="white">
            {name}
          </Text>
        )}
        {occupation && (
          <Text as="span" type="detail" color="gray100">
            {occupation}
          </Text>
        )}
      </div>
    </div>
  );
};

Profile.displayName = 'Sidebar.Profile';

export default Profile;
