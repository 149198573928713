import { createContext, useMemo } from 'react';

type SidebarContextProps = {
  collapsed?: boolean;
};

export const SidebarContext = createContext<SidebarContextProps>({});

type SidebarProviderProps = {
  children: React.ReactNode;
} & SidebarContextProps;

export const SidebarProvider = ({ children, collapsed }: SidebarProviderProps) => {
  const value = useMemo(() => ({ collapsed }), [collapsed]);

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
