import { CSSProperties } from 'react';

import { colors } from 'src/styles';

import styles from './styles.module.scss';

const Loader = ({ size = 100, width = 5, color = colors.blue400, backgroundColor = colors.gray200 }) => {
  return (
    <div
      className={styles.loader}
      style={
        {
          '--size': `${size}px`,
          '--width': `${width}px`,
          '--color': color,
          '--background-color': backgroundColor
        } as CSSProperties
      }
    />
  );
};

export default Loader;
