import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import Providers from 'src/providers';

import App from './App.tsx';

import 'react-toastify/dist/ReactToastify.css';
import 'src/styles/globals.scss';

import('react-modal').then((module) => module.default.setAppElement(root));
import('src/scripts/buy-me-a-coffee').then((module) => module.default());
import('src/scripts/sentry').then((module) => module.default());
import('src/scripts/hotjar').then((module) => module.default());

const root = document.getElementById('root')!;

const toastContainerStyle = { '--toastify-z-index': 99999 } as CSSProperties;

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <Providers>
      <App />
      <ToastContainer position="top-right" style={toastContainerStyle} />
    </Providers>
  </React.StrictMode>
);
