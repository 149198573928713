import { useQuery } from '@tanstack/react-query';

import queryKeys from '../keys';

import PermissionsAPI from './api';

type UseGetUserPermissionsProps = {
  id?: string;
};

export const useGetUserPermissions = ({ id = '' }: UseGetUserPermissionsProps) => {
  return useQuery({
    queryKey: queryKeys.permission(id),
    queryFn: () => PermissionsAPI.getUserPermissions(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });
};
