import { useGetUserPermissions } from 'src/api/permissions';

import useAuth from '../useAuth';

export default function usePermissions() {
  const { user } = useAuth();
  const { data: permissions, isLoading } = useGetUserPermissions({ id: user?.uid });

  const canCreateTemplate = permissions?.permissions.template.write;

  return {
    isLoading,
    canCreateTemplate
  };
}
