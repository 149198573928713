import { forwardRef } from 'react';
import clsx from 'clsx';

import { PolymorphicComponentPropsWithRef, PolymorphicRef } from 'src/types/polymorphic';

import { colors } from 'src/styles';

import styles from './styles.module.scss';

const FONT_TYPES = [
  'h1',
  'h2',
  'h3',
  'h4',
  'large',
  'lead',
  'p',
  'p-ui',
  'p-ui-medium',
  'list',
  'body',
  'body-medium',
  'subtle',
  'subtle-medium',
  'subtle-semibold',
  'small',
  'detail'
] as const;

type Props = {
  type?: (typeof FONT_TYPES)[number];
  color?: keyof typeof colors;
  center?: boolean;
  ellipsis?: boolean;
};

export type TextProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type TextComponent = (<C extends React.ElementType = 'span'>(props: TextProps<C>) => React.ReactNode) & {
  displayName?: string;
};

const BaseText: TextComponent = forwardRef(
  <C extends React.ElementType = 'span'>(
    { as, children, className, type, color, style, center, ellipsis, ...rest }: TextProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    if (type && !FONT_TYPES.includes(type)) {
      throw new Error(`Invalid type: ${type}. Must be one of: ${FONT_TYPES.join(', ')}`);
    }

    const Component = as || 'span';

    return (
      <Component
        {...rest}
        ref={ref}
        className={clsx(className, styles.text, {
          [styles[type || '']]: !!type,
          [styles.center]: center,
          [styles.ellipsis]: ellipsis
        })}
        style={{
          ...style,
          '--text-color': color && color in colors ? colors[color] : color
        }}
      >
        {children}
      </Component>
    );
  }
);

BaseText.displayName = 'BaseText';

export default BaseText;
