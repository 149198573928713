import { ComponentProps, ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

export default function withAuthProtection(Component: ComponentType) {
  return function WithAuthProtection(props: ComponentProps<ComponentType>) {
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/login" />;
    }

    return <Component {...props} />;
  };
}
