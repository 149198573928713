import { ButtonHTMLAttributes } from 'react';

import { RxHamburgerMenu } from 'react-icons/rx';

import Button from 'src/kit/button';

export type ToggleProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Toggle = (props: ToggleProps) => {
  return (
    <Button {...props} flavor="white">
      <RxHamburgerMenu />
    </Button>
  );
};

export default Toggle;
