import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export type SkeletonProps = HTMLAttributes<HTMLDivElement> & {
  height?: string | number;
  width?: string | number;
  borderRadius?: string;
  darker?: boolean;
  style?: React.CSSProperties;
};

const Skeleton = ({
  width = '100%',
  height = '16px',
  darker,
  borderRadius = '4px',
  style = {},
  ...props
}: SkeletonProps) => {
  return (
    <div
      {...props}
      className={clsx(props.className, styles.skeleton, {
        [styles.darker]: darker
      })}
      style={{ ...style, width, height, borderRadius }}
    />
  );
};

Skeleton.displayName = 'Skeleton';

export default Skeleton;
