import clsx from 'clsx';

import styles from './styles.module.scss';

type ListProps = {
  children: React.ReactNode;
  className?: string;
};

const List = (props: ListProps) => {
  const { children, className, ...rest } = props;

  return (
    <ul {...rest} className={clsx(className, styles['list'])}>
      {children}
    </ul>
  );
};

List.displayName = 'Sidebar.List';

export default List;
