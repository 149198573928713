import { HTMLAttributes } from 'react';
import clsx from 'clsx';

import Profile from './profile';
import Toggle from './toggle';

import styles from './styles.module.scss';

type NavbarSubComponents = {
  Profile: typeof Profile;
  Toggle: typeof Toggle;
};

type NavbarProps = HTMLAttributes<HTMLDivElement>;
type NavbarType = React.FC<NavbarProps> & NavbarSubComponents;

const Navbar: NavbarType = ({ className, children, ...props }) => {
  return (
    <nav {...props} className={clsx(className, styles.navbar)}>
      {children}
    </nav>
  );
};

Navbar.Profile = Profile;
Navbar.Toggle = Toggle;

export default Navbar;
