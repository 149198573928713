import { HTMLAttributes } from 'react';
import clsx from 'clsx';

import Item from './item';
import List from './list';
import Profile from './profile';
import SidebarProvider from './shared/provider';

import styles from './styles.module.scss';

type SidebarProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  collapsed?: boolean;
};

type SidebarSubComponents = {
  Profile: typeof Profile;
  List: typeof List;
  Item: typeof Item;
};

type SidebarType = React.FC<SidebarProps> & SidebarSubComponents;

const Sidebar: SidebarType = ({ children, collapsed, ...rest }) => {
  return (
    <SidebarProvider collapsed={collapsed}>
      <div {...rest} className={clsx(styles.sidebar, { [styles.collapsed]: collapsed })}>
        {children}
      </div>
    </SidebarProvider>
  );
};

Sidebar.Profile = Profile;
Sidebar.List = List;
Sidebar.Item = Item;

export default Sidebar;
