import styles from './styles.module.scss';

type OverlayProps = {
  children: React.ReactNode;
};

const Overlay = ({ children }: OverlayProps) => {
  return <div className={styles['page-overlay']}>{children}</div>;
};

export default Overlay;
